exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-productos-index-jsx": () => import("./../../../src/pages/productos/index.jsx" /* webpackChunkName: "component---src-pages-productos-index-jsx" */),
  "component---src-pages-productos-sinsal-jsx": () => import("./../../../src/pages/productos/sinsal.jsx" /* webpackChunkName: "component---src-pages-productos-sinsal-jsx" */),
  "component---src-pages-recetas-index-jsx": () => import("./../../../src/pages/recetas/index.jsx" /* webpackChunkName: "component---src-pages-recetas-index-jsx" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

